import React, { Component } from 'react';
import  { connect } from 'react-redux';
import { setVersion } from './../store/actions/Index';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class TimeMachineModal extends Component {
    constructor(props) {
        super(props);

        this.performTimeTravel = this.performTimeTravel.bind(this);
    }

    performTimeTravel() {
        this.props.closeModal();
        this.props.setVersion(this.props.year);
    }

    render() {
        return (
            <Modal isOpen={this.props.show}>
                <ModalHeader>
                    {`¿Estás seguro de viajar a la version del ${this.props.year}?`}
                </ModalHeader>
                <ModalBody>
                    <p>
                        Viajar en el tiempo te permite ver las versiones anteriores de la página. Sin embargo,
                        solo funciona como un archivo, por lo que las cosas nuevas no podrán verse mas que en
                        la version más reciente.
                        <br /><br/>
                        Después viajar en el tiempo, cada que visites la página verás la versión a la que
                        hayas viajado. Esta configuración se guardará a menos que elimines el historial
                        y los datos guardados de tu navegador.
                        <br /><br/>
                        Recuerda que siempre puedes regresar a la versión más actual del sitio.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={this.props.closeModal}>
                        Cancelar
                    </button>
                    <button
                        type="button"
                        className="btn btn-info"
                        onClick={this.performTimeTravel}>
                        Sí, viajar al {this.props.year}.
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

TimeMachineModal.defaultProps = {
    show: false,
}

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setVersion: (version) => dispatch(setVersion(version))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeMachineModal);