import axios from 'axios';

export default class ImageFetcherService {
    fetchImages(images = []) {
        const url = "https://fernandas-page-bucket.s3.us-west-1.amazonaws.com/images/";
        let imgs = [];
        images.map(img => imgs.push(`${url}${img}`));

        return imgs;
    }
}