import React, { Component, Fragment } from 'react';
import ImageFetcherService from '../utils/ImageFetcherService';
import { connect } from 'react-redux';
import { getRandomColor, shuffleArray } from '../utils/utils';
import moment from 'moment';

import {
    Card, CardImg, CardTitle, CardText, CardColumns,
    CardBody, Container
} from 'reactstrap';
import GalleryCardImage from '../components/GalleryCardImage';

import { BlogCard, Button } from 'tabler-react';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: '',
            jumbotronClass: '',
        };

        shuffleArray(this.props.images);
    }

    componentWillMount() {
        let images = {};
        let imagesToFetch = [];
        this.props.images.map((img) => {
            images[img.path] = '';
            imagesToFetch.push(img.path);
        });

        let service = new ImageFetcherService();
        let fetched = service.fetchImages(imagesToFetch);
        for (let i = 0; i < fetched.length; ++i) {
            images[imagesToFetch[i]] = fetched[i];
        }

        this.setState({
            images: images,
            jumbotronClass: 'jumbotron',
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return this.render2020Version();
    }

    render2017Version() {
        return (
            <Fragment>
                <div style={{'backgroundImage': `url(${this.state.images['index/jumbotron.jpg']})`}} class={`bg-light mb-4 py-3 py-sm-5 ${this.state.jumbotronClass}`}>
                    <Container fluid>
                        <h1 className="display-3">¡Feliz Navidad!</h1>
                        <p>¡Hola mi flaquita hermosa!
                        <br/>
                        En esta navidad, quise hacerte un regalo único y que pudieras ver siempre; un regalo que, no importa donde estés, puedas abrirlo y recordar lo mucho que te amo y que te quiero.
                        <br/>
                        Este es un regalo chiquito, pero que espero te guste. Es una lista de canciones que me recuerdan a ti; desde que te conocí hasta esta Navidad en la que seguimos juntos mi amor.
                        <br/>
                        Gracias por todo mi flaquita y quiero seguir haciendote feliz muchos muchos muchos años más.</p>
                        <h4>Por favor, antes de ir a las canciones, ve hasta abajo de está página</h4>
                        <a href='/2017/canciones' className="btn btn-lg btn-success">Canciones</a> 
                    </Container>
                </div>
                <CardColumns>
                    {
                        this.props.images.map((image, index) => (
                            <Fragment>
                                {
                                    image.title !== 'Jumbotron' &&
                                    <Card key={index} body outline color={getRandomColor()}>
                                        <CardImg top width="100%" src={this.state.images[image.path]} alt="...cargando" />
                                        <CardBody>
                                            <CardTitle>{image.title}</CardTitle>
                                            <CardText>{image.description}</CardText>
                                        </CardBody>
                                    </Card>
                                }
                            </Fragment>
                        ))
                    }
                </CardColumns>
            </Fragment>
        );
    }

    render2020Version() {
        const description = (
            <Fragment>
                <p>
                    <br/>
                    <b>¡Hola mi flaquita hermosa!</b><br/>
                    <br/>
                    En esta navidad, quise hacerte un regalo único y que pudieras ver siempre; un regalo que, no importa donde estés, puedas abrirlo y recordar lo mucho que te amo y que te quiero.<br/>
                    Este es un regalo chiquito, pero que espero te guste. Es una lista de canciones que me recuerdan a ti; desde que te conocí hasta esta Navidad en la que seguimos juntos mi amor.<br/>
                    Gracias por todo mi flaquita y quiero seguir haciendote feliz muchos muchos muchos años más.<br/><br/>

                    Por favor, antes de ir a las canciones, ve hasta abajo de está página.
                </p>
                <a class="btn btn-purple" href='/2017/canciones'>
                    <i className='fa fa-compact-disc fa-lg'/> &nbsp;Canciones
                </a>
            </Fragment>
        );

        return (
            <div className="homepage">
                <BlogCard
                    title="¡Feliz Navidad!"
                    description={description}
                    avatarImgSrc={this.state.images[this.props.images[Math.floor(Math.random() * this.props.images.length)].path]}
                    authorName="Espo-oso"
                    date={moment('20171224', 'YYYYMMDD').locale('es-mx').fromNow()}
                    imgSrc={this.state.images['index/jumbotron.jpg']}
                    imgAlt="Amor"
                    iconName="remove-me"
                />
                <CardColumns>
                    {
                        this.props.images.map((img, idx) =>
                            <Fragment>
                                {
                                    img.title !== 'Jumbotron' &&
                                    <GalleryCardImage
                                        key={`homepage-image-${idx}`} 
                                        url={this.state.images[img.path]}
                                        avatarURL={this.state.images[img.path]}
                                        title={img.title}
                                        description={img.description}
                                        status={true}
                                    />
                                }
                            </Fragment>
                        )
                    }
                </CardColumns>
            </div>
        );
    }
}

Home.defaultProps = {
    'images': [
        {
            'title': 'Jumbotron',
            'description': '',
            'path': 'index/jumbotron.jpg'
        },
        {
            'title': 'Besito',
            'description': 'Un besito bonito, y tu sonrisa me encanta!!!',
            'path': 'index/1.jpg'
        },
        {
            'title': 'Tu carita toda hermosa',
            'description': 'Y mi cara cuando la veo',
            'path': 'index/2.jpg'
        },
        {
            'title': 'La bella y la bestia',
            'description': 'Un besito bonito y yo con mi cara de Bob Esponja cavernícola.',
            'path': 'index/3.jpg'
        },
        {
            'title': 'En el parque',
            'description': 'Acostados en una banquita haciendo caritas',
            'path': 'index/4.jpg'
        },
        {
            'title': 'La mordida',
            'description': 'Un besito con mordida que me diste...TE AMOOOO!!!',
            'path': 'index/5.jpg'
        },
        {
            'title': 'El Jesuscristo',
            'description': 'Haciendo la seña particular de Yisus en todas sus pinturas',
            'path': 'index/6.jpg'
        },
        {
            'title': 'Besito',
            'description': 'Tu sonrisa hermosa y un besito con ojitos',
            'path': 'index/7.jpg'
        },
        {
            'title': 'Bob Esponja',
            'description': '...carvernícola',
            'path': 'index/8.jpg'
        },
        {
            'title': 'Siendo chaka',
            'description': 'Simón sí sí',
            'path': 'index/9.jpg'
        },
        {
            'title': 'Besito',
            'description': 'Tus besitos me encantan mi amor, TE AMOOOO!!!!',
            'path': 'index/10.jpg'
        },
        {
            'title': 'Cuando nos comprometimos',
            'description': 'Te amo mucho mi amor :D',
            'path': 'index/11.jpg'
        },
    ]
}

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Home);