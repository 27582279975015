import React, { Component, Fragment } from 'react';
import GeneralUIComponent from './GeneralUIComponent';
import { Card } from 'tabler-react';

export default class GeneralSitePage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Card className="general-ui-component">
                <Card.Body>
                {
                    this.props.content.map((c, i) => 
                        <GeneralUIComponent component={c} key={`general-page-component-${i}`}/>
                    )
                }
                </Card.Body>
            </Card>
        );
    }

}

GeneralSitePage.defaultProps = {
    content: []
}