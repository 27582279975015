import Home from './../../pages/Home';

// 2017
import Canciones from './../../pages/2017/Canciones';
import Navidad2017 from './../../pages/2017/Navidad';
import AnioNuevo2017 from './../../pages/2017/AnioNuevo';

// 2018
import Febrero2018 from './../../pages/2018/Febrero';
import Cumpleanios2018 from './../../pages/2018/Cumpleanios';
import Julio2018 from './../../pages/2018/Julio';
import Navidad2018 from './../../pages/2018/Navidad';
import AnioNuevo2018 from './../../pages/2018/AnioNuevo';

// 2019
import Cumpleanios2019 from './../../pages/2019/Cumpleanios';
import Navidad2019 from './../../pages/2019/Navidad';
import AnioNuevo2019 from './../../pages/2019/AnioNuevo';

// 2020
import Febrero2020 from './../../pages/2020/Febrero';
import Cumpleanios2020 from './../../pages/2020/Cumpleanios';
import Navidad2020 from './../../pages/2020/Navidad';
import AnioNuevo2020 from './../../pages/2020/AnioNuevo';

// 2021
import Navidad2021 from './../../pages/2021/Navidad';

// 2022
import Enero2022 from './../../pages/2022/Enero';
import Cumpleanios2022 from './../../pages/2022/Cumpleanios';
import Navidad2022 from './../../pages/2022/Navidad';
import AnioNuevo2022 from './../../pages/2022/AnioNuevo';

// 2023
import Cumpleanios2023 from './../../pages/2023/Cumpleanios';

// 2024
import Cumpleanios2024 from './../../pages/2024/Cumpleanios';

const initialState = {
    routes: {
        2017: [
            {
                path: '/',
                component: Home,
                name: 'Home',
                year: 2017,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2017/canciones',
                component: Canciones,
                name: 'Canciones',
                year: 2017,
                hasIcon: true,
                icon: 'fa-record-vinyl',
            },
            {
                path: '/2017/navidad',
                component: Navidad2017,
                name: 'Navidad',
                year: 2017,
                hasIcon: true,
                icon: 'fa-holly-berry',
            },
            {
                path: '/2017/anio-nuevo',
                component: AnioNuevo2017,
                name: 'Año Nuevo',
                year: 2017,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2018/14-de-febrero',
                component: Febrero2018,
                name: 'Febrero',
                year: 2018,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2018/cumpleanios',
                component: Cumpleanios2018,
                name: 'Cumpleaños',
                year: 2018,
                hasIcon: true,
                icon: 'fa-birthday-cake',
            },
            {
                path: '/2018/julio',
                component: Julio2018,
                name: 'Julio',
                year: 2018,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2018/navidad',
                component: Navidad2018,
                name: 'Navidad',
                year: 2018,
                hasIcon: true,
                icon: 'fa-holly-berry',
            },
            {
                path: '/2018/anio-nuevo',
                component: AnioNuevo2018,
                name: 'Año Nuevo',
                year: 2018,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2019/cumpleanios',
                component: Cumpleanios2019,
                name: 'Cumpleaños',
                year: 2019,
                hasIcon: true,
                icon: 'fa-birthday-cake',
            },
            {
                path: '/2019/navidad',
                component: Navidad2019,
                name: 'Navidad',
                year: 2019,
                hasIcon: true,
                icon: 'fa-holly-berry',
            },
            {
                path: '/2019/anio-nuevo',
                component: AnioNuevo2019,
                name: 'Año Nuevo',
                year: 2019,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2020/febrero',
                component: Febrero2020,
                name: 'Febrero',
                year: 2020,
                hasIcon: false,
                icon: '',
            },
        ],
        2020: [
            {
                path: '/2020/cumpleanios',
                component: Cumpleanios2020,
                name: 'Cumpleaños',
                year: 2020,
                hasIcon: true,
                icon: 'fa-birthday-cake',
            },
            {
                path: '/2020/navidad',
                component: Navidad2020,
                name: 'Navidad',
                year: 2020,
                hasIcon: true,
                icon: 'fa-holly-berry',
            },
            {
                path: '/2020/anio-nuevo',
                component: AnioNuevo2020,
                name: 'Año Nuevo',
                year: 2020,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2021/navidad',
                component: Navidad2021,
                name: 'Navidad',
                year: 2021,
                hasIcon: true,
                icon: 'fa-holly-berry',
            },
            {
                path: '/2022/enero',
                component: Enero2022,
                name: 'Enero',
                year: 2022,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2022/cumpleanios',
                component: Cumpleanios2022,
                name: 'Cumpleaños',
                year: 2022,
                hasIcon: true,
                icon: 'fa-birthday-cake',
            },
            {
                path: '/2022/navidad',
                component: Navidad2022,
                name: 'Navidad',
                year: 2022,
                hasIcon: true,
                icon: 'fa-holly-berry',
            },
            {
                path: '/2022/anio-nuevo',
                component: AnioNuevo2022,
                name: 'Año Nuevo',
                year: 2022,
                hasIcon: false,
                icon: '',
            },
            {
                path: '/2023/cumpleanios',
                component: Cumpleanios2023,
                name: 'Cumpleaños',
                year: 2023,
                hasIcon: true,
                icon: 'fa-birthday-cake',
            },
            {
                path: '/2024/cumpleanios',
                component: Cumpleanios2024,
                name: 'Cumpleaños',
                year: 2024,
                hasIcon: true,
                icon: 'fa-birthday-cake',
            },
        ],
    },
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        default:
            return state;
    }
}

export default reducer;
