import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Cumpleanios extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Cumpleanios.defaultProps = {
    content: [
        {
            type: "image",
            value: '2022/cumpleanios/cumpleanios.jpg',
            title: 'Quasimodos',
            description: 'No encontre otra foto más reciente de nosotros, pero puse las locuras de mono.'
        },
        {
            type: "header",
            value: `¡Feliz cumpleaños monito loco!`
        },
        {
            type: "text",
            value: `Te amo mucho, y quiero que sepas que eres mi vida y mi todo. Ayer esposa tenñia 23 años, casí 24. Pero hoy tienes 24 años.`
        },
        {
            type: "text",
            value: `Eres mi todo amor, y cada día eres más hermosa, más chistosa. Pero sobre todo, cada día me haces un esposo más feliz.`
        },
        {
            type: "text",
            value: `Perdón por haber estado de malas ayer mi amor. Oso te ama mucho, en verdad perdona a esposo. Oso te ama y quiere estar a tu lado siempre.`
        },
        {
            type: "text",
            value: `Muchas gracias por estar con oso, por cuidarlo, por aguantarlo, y por estar a su lado. Te amo mucho, y me siento muy feliz de poder estar otro año más junto a mi monito loco.`
        },
        {
            type: "text",
            value: `Te amo, y quiero mandarte muchos abrazos locos, con piojito y masajito de patitas para que estes llena de cariñitos de esposo. Sabes que me hace muy feliz estar a tu lado, cuidandote, abrazandote, viendo tus locuras en TikTok. No importa que este haciendo con monito. Siempre que estás a mi lado me haces muy feliz, y espero que mono este feliz de estar con oso.`
        },
        {
            type: "text",
            value: `Te amo mucho esposa. Ya quiero estar a tu lado, darte todos tus abrazos, y comprarte tu pastel loco que quieres desde hace tiempo. También espero que te hayan gustado tus regalos, a osito le gusta mucho como te ves con las cosas que te compro, porque te ves muy hermosa y sexy.`
        },
        {
            type: "text",
            value: `Eres mi todo mi amor. ¡Feliz cumpleaños monito amoroso, loco y chistoso!.`
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Cumpleanios);