import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Cumpleanios extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Cumpleanios.defaultProps = {
    content: [
        {
            type: "image",
            value: '2020/cumpleanios2020.png',
            alt: '2020/cumpleanios_thumb.png',
            title: 'Partiendo un pastelito juntos',
            description: 'Prometo que vamos a festejar tu cumpleaños juntos y con pastelito'
        },
        {
            type: "header",
            value: "Hola esposa hermosa, ¡muchas felicidades por tu cumpleaños!.",
            size: 4,
        },
        {
            type: "text",
            value: `Quiero decirte que te amo mucho, y que me siento muy triste por no poder estar con mi osita hermosa y encimosa el día de su cumpleaños. Pero te prometo que cuando pueda estar a tu lado vamos a celebrar tu cumpleaños, con besitos, abrazos y pastelito y muchos besitos con sabor a pastelito.`
        },
        {
            type: "text",
            value: `Pero al mismo tiempo, es un día bonito, porque hoy, hace 22 años nació la persona que me hace el esposo más feliz del mundo y con la que quiero pasar mi vida a su lado, y poder celebrar sus cumpleaños juntitos.`,
        },
        {
            type: "text",
            value: `En mi tiempo libre estuve preparando este regalo chiquito, si ya te diste cuenta la página se ve diferente. Decidí actualizarla para que se viera mejor, pero quise conservar la versión original, para que siempre recuerdes como era cuando te la regale en Navidad, y siempre puedas volver a verla (en el menú esta la opción de Time Machine). Espero que la forma en la que se ve ahora te guste mucho como antes.`,
        },
        {
            type: "text",
            value: `Te amo mucho mi amor, gracias por ser la mejor esposita del mundo, y me siento muy feliz de que sigas a mi lado después de estos tres años, me hace muy feliz, y quiero que sigamos estando juntos, no sólo muchos años más, sino toda la vida. Quiero que podamos casarnos, estar juntitos, y tener una casita donde estemos juntitos todos los días, y que nuestro Busti gordito nos acompañe.`,
        },
        {
            type: "text",
            value: "También quiero decirte que no hay nada más bonito en el mundo que ver tus ojitos, y verte feliz y verte sonreir. Cuando estas a mi lado, siempre me emociono al ver como sonríes al estar conmigo, y ver que te hago feliz. Amo poder hacerte cosquillitas, darte masajitos, dormir a tu lado, y sentirme siempre el espo-oso más afortunado del mundo. Todo me encanta de ti mi amor, y eres una persona muy linda siempre.Quiero abrazarte toda la vida, porque, noy hay nada más bonito que poder tenerte en mis bracitos y saber que te lleno de amor y cariñitos con cada uno de ellos."
        },
        {
            type: "text",
            value: `Te amo, mucho, y espero que tengas un día muy bonito, y que puedas comer pastelito, y dulces. Te amo con todo mi corazón amor, te dejo estas mañanitas para que empieces tu día muy bonito y feliz.`,
        },
        {
            type: "text",
            value: `Atentamente. Tu esposo que te ama mucho.`,
        },
        {
            type: "header",
            value: `¡Felicidades esposa!`,
            size: 3
        },
        {
            type: "video",
            value: {
                'title': 'Las mañanitas',
                'avatarName': 'Pedro Infante',
                'avatarURL': 'https://i.scdn.co/image/ccf922f75bb15f90b619cbb55f2f7f9c2ccce836',
                'videoURL': 'https://www.youtube.com/embed/7fpd4N5T5qw'
            },
        }
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Cumpleanios);