import ReactDOM from 'react-dom';
import Wrapper from './src/Wrapper';
import { Provider } from 'react-redux';
import ConfigureStore from './src/store/ConfigureStore';

const store = ConfigureStore();

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

ReactDOM.render(<Provider store={store}><Wrapper /></Provider>, document.getElementById("page"));