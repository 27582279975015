import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageFetcherService from './../../utils/ImageFetcherService';
import GeneralSitePage from '../../components/GeneralSitePage';

class AnioNuevo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2019/anio-nuevo/anionuevo.jpg']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="navidad-2017">
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.image} />
                </div>
                {
                    this.props.text.map((t, i) => 
                        <p key={i}>
                            {t}
                            <br/><br/>
                        </p>
                    )
                }
                <h3>¡TE AMO MUCHO OSITA HERMOSA!</h3>
            </div>
        );
    }
}

AnioNuevo.defaultProps = {
    text: [
        `Hola mi amor, buenas noches. Quiero desearte un feliz año nuevo. Y también agradecerte por todo el amor y el apoyo que siempre me das día con día. Se que este año podría ser difícil para nosotros, pero te agradezco mucho por apoyarme y también te prometo que te voy a venir a ver seguido, porque tu eres mi vida, y eres mi todo, y tu eres la koalita con la que quiero compartir mi vida.`,
        `Esta página, aunque es un regalito sencillo, me gusta porque siento que aquí vamos plasmando momentos bonitos de nuestra historia como novios y esposos. Espero que te guste siempre este regalo y que sepas que aquí siempre escribiré las cosas bonitas que hemos pasado juntos.`,
        `Te amo mucho esposa, muchas, muchas gracias por estos años en los que me has amado, y me has cuidado. Gracias por pasar estas vacaciones conmigo, las cuales van a ser las primeras de muchas en las que estaremos juntos, conociendo lugares bonitos y llenandonos de besitos.`,
        `También quiero decirte si ¿quieres casarte conmigo?, porque quiero ir a la camita todas las noches a abrazar a mi esposa, y despertar todas las mañanas con tus ojitos y tus besitos amorosos. Te amo mucho y quiero pasar mi vida a tu lado.`,
        `Gracias por hacerme el osito más feliz del mundo.`
    ],
    content: [
        {
            type: "image",
            value: '2019/anio-nuevo/anionuevo.jpg',
            title: 'Los ositos',
            description: 'Visitando Bácalar Parte II'
        },
        {
            type: "text",
            value: "Hola mi amor, buenas noches. Quiero desearte un feliz año nuevo. Y también agradecerte por todo el amor y el apoyo que siempre me das día con día. Se que este año podría ser difícil para nosotros, pero te agradezco mucho por apoyarme y también te prometo que te voy a venir a ver seguido, porque tu eres mi vida, y eres mi todo, y tu eres la koalita con la que quiero compartir mi vida.",
        },
        {
            type: "text",
            value: "Esta página, aunque es un regalito sencillo, me gusta porque siento que aquí vamos plasmando momentos bonitos de nuestra historia como novios y esposos. Espero que te guste siempre este regalo y que sepas que aquí siempre escribiré las cosas bonitas que hemos pasado juntos."
        },
        {
            type: "text",
            value: "Te amo mucho esposa, muchas, muchas gracias por estos años en los que me has amado, y me has cuidado. Gracias por pasar estas vacaciones conmigo, las cuales van a ser las primeras de muchas en las que estaremos juntos, conociendo lugares bonitos y llenandonos de besitos."
        },
        {
            type: "text",
            value: "También quiero decirte si ¿quieres casarte conmigo?, porque quiero ir a la camita todas las noches a abrazar a mi esposa, y despertar todas las mañanas con tus ojitos y tus besitos amorosos. Te amo mucho y quiero pasar mi vida a tu lado.",
        },
        {
            type: "text",
            value: "Gracias por hacerme el osito más feliz del mundo.",
        },
        {
            type: "header",
            value: "¡TE AMO MUCHO OSITA HERMOSA!",
            size: 3
        }
    ]    
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(AnioNuevo);