import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageFetcherService from './../../utils/ImageFetcherService';
import GeneralSitePage from '../../components/GeneralSitePage';

class Navidad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2019/navidad/navidadp.jpg']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="navidad-2017">
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.image} />
                </div>
                <p>
                    Hola mi amor, vine a hacerte esta cartita de navidad.
                    <br />
                </p>
                <p>
                    Este es un regalito de Navidad extra, ya que espero que mis regalos te hayan gustado mucho.
                    <br /><br />
                    Te amo con todo mi corazon esposa hermosa, y quiero agradecerte mucho, por estar estas vacaciones conmigo, por pasar la navidad conmigo, y por ya haber dormido muchos días a mi lado. Es bonito porque me cuidas mucho y me hace muy feliz como me tapas y me abrazas para que no tenga frío.
                    <br /><br />
                    Muchas gracias mi amor, por estar junto a osito, en verdad, me haces muy feliz. Y aunque a veces me despiertes en la noche. Me encanta saber que estoy a tu lado toda la noche. (Perdón por sentarme en tu celular)
                    <br /><br />
                    Gracias por todo tu amor, me haces el osito más feliz y el esposo más afortunado del mundo. Espero que te haya gustado la foto que puse. Me gusto mucho estar abrazado de mi esposa e ir a conocer lugares muy bonitos junto al amor de mi vida.
                    <br /><br />
                    Cuando leas esto, ve por tu otro regalo. Muchos besitos y apapachos de tu esposo amoroso.
                    <br /><br />
                    <h3>¡TE AMO ESPOSA!</h3>
                </p>
            </div>
        );
    }
}

Navidad.defaultProps = {
    content: [
        {
            type: "image",
            value: '2019/navidad/navidadp.jpg',
            title: 'Los ositos',
            description: 'Visitando Bácalar'
        },
        {
            type: "text",
            value: "Hola mi amor, vine a hacerte esta cartita de navidad.",
        },
        {
            type: "text",
            value: "Este es un regalito de Navidad extra, ya que espero que mis regalos te hayan gustado mucho."
        },
        {
            type: "text",
            value: "Te amo con todo mi corazon esposa hermosa, y quiero agradecerte mucho, por estar estas vacaciones conmigo, por pasar la navidad conmigo, y por ya haber dormido muchos días a mi lado. Es bonito porque me cuidas mucho y me hace muy feliz como me tapas y me abrazas para que no tenga frío."
        },
        {
            type: "text",
            value: "Muchas gracias mi amor, por estar junto a osito, en verdad, me haces muy feliz. Y aunque a veces me despiertes en la noche. Me encanta saber que estoy a tu lado toda la noche. (Perdón por sentarme en tu celular)",
        },
        {
            type: "text",
            value: "Gracias por todo tu amor, me haces el osito más feliz y el esposo más afortunado del mundo. Espero que te haya gustado la foto que puse. Me gusto mucho estar abrazado de mi esposa e ir a conocer lugares muy bonitos junto al amor de mi vida.",
        },
        {
            type: "text",
            value: "Cuando leas esto, ve por tu otro regalo. Muchos besitos y apapachos de tu esposo amoroso.",
        },
        {
            type: "header",
            value: "¡TE AMO ESPOSA!",
            size: 3
        }
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Navidad);