import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Enero extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Enero.defaultProps = {
    content: [
        {
            type: "image",
            value: '2022/enero/zoologico.JPG',
            title: 'En el zoológico',
            description: 'El día que mono me compro unos chilaquiles feos'
        },
        {
            type: "text",
            value: `Hola mi amor, primero quiero decirte que eres un mono muy loco y muy amoroso. Te amo mucho y me haces feliz siempre.`
        },
        {
            type: "text",
            value: `También quiero decirte que me sentí muy triste porque el año pasado no te escribí tanto, y cuando podía escribirte, no podía subir los cambios a la página que te hice. Me sentí muy enojado y frustrado porque no sabía qué pasaba y tardaba mucho tiempo para ver si mis cambios funcionaban.`
        },
        {
            type: "text",
            value: `Ya nunca pude subir nada, así que decidí cambiar el sitio y ponerlo en mi propio servidor. Tuve que hacer muchos cambios para que nadie más que tú y yo podamos entrar al sitio y ver lo que le escribo a mono, porque ahora el sitio es más fácil de acceder.`
        },
        {
            type: "text",
            value: `Me espere a mostrarte esto hasta que tuvieras tu iPhone y pudieras guardar contraseñas y códigos de autenticación para que entrar aquí te sea más fácil.`
        },
        {
            type: "text",
            value: `Te amo mucho esposa, y te mando otra vez una Feliz Navidad, y un bonito Año Nuevo, aunque sea atrasado.`
        },
        {
            type: "text",
            value: `Te amo, y quiero agradecerte que siempre me apoyas, me amas, me cuidas y eres una esposa amorosa todos los días con oso. Te amo mucho, y quiero llenarte de abrazos y masajito de patita!`
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Enero);