import React, { Component, Fragment } from 'react';
import { GalleryCard } from 'tabler-react';
import { getRandomHEXColor } from '../utils/utils';

export default class GalleryCardImage extends Component {

    render() {
        return (
            <GalleryCard>
                {
                    this.props.status &&
                    <div className={`card-status ${Math.round(Math.random()) == 0 ? `` : `card-status-left`}`} style={{ backgroundColor: getRandomHEXColor() }} />
                }
                <GalleryCard.Image src={this.props.url} />
                <hr/>
                <GalleryCard.Footer>
                    <GalleryCard.Details
                        avatarURL={this.props.avatarURL}
                        fullName={this.props.title}
                        dateString={this.props.description}
                    />
                </GalleryCard.Footer>
            </GalleryCard>
        )
    }

}

GalleryCardImage.defaultProps = {
    url: '',
    avatarURL: '',
    title: '',
    description: '',
    status: false
}