import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { Nav as TablerNav } from 'tabler-react';
import  { connect } from 'react-redux';
import TimeMachineModal from './TimeMachineModal';
import NavLink from '../models/NavLink';
import NavItemRender from './NavItemRender';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            modal: {
                show: false,
                year: 2020,
            }
        }

        this.toggle = this.toggle.bind(this);
        this.prepareTimeMachineTravel = this.prepareTimeMachineTravel.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    toggle() {
        let isOpen = this.state.isOpen;
        this.setState({
            isOpen: !isOpen,
        });
    }

    prepareTimeMachineTravel(toVersion) {
        this.setState({
            modal: {
                show: true,
                year: toVersion
            }
        });
    }

    closeModal() {
        this.setState({
            modal: {
                isOpen: false,
                year: this.state.modal.year,
            }
        });
    }

    render() {
        let version = this.props.version;
        let routes = this.props.routes;
        let availableRoutes = {};

        /** This extra iteration is needed for compatibility reasons with Safari */
        Object.keys(routes).map((year) => {
            if (version >= year) {
                let r = routes[year];
                r.map((route) => {
                    availableRoutes[route.year] = [ ];
                });
            }
        });

        Object.keys(routes).map((year) => {
            if (version >= year) {
                let r = routes[year];
                r.map((route) => {
                    availableRoutes[route.year].push(route);
                });
            }
        });
        
        console.log(availableRoutes);

        if (this.props.version === 2017)
            return this.render2017Version(availableRoutes);
        else
            return this.render2020Version(availableRoutes);
    }

    render2017Version(routes) {
        return (
            <Fragment>
                <TimeMachineModal show={this.state.modal.show} year={this.state.modal.year} closeModal={this.closeModal}/>
                <Navbar className="header" color="light" light expand="md">
                    <NavbarBrand className="navbarBrand" href="/">
                        Para Fernanda&nbsp; <i className='fa fa-heart heartIcon'/>
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ms-auto" navbar>
                            {
                                Object.keys(routes).map((year) => (
                                    <UncontrolledDropdown key={year} nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {year}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                routes[year].map((route) => (
                                                    <Fragment>
                                                        {
                                                            route.path !== '/' &&
                                                            <DropdownItem key={route.path}>
                                                                <Link to={route.path}>{route.name}</Link>
                                                            </DropdownItem>
                                                        }
                                                    </Fragment>
                                                ))
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                ))
                            }

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Time Machine
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        this.props.availableVersions.map((year) => 
                                            this.props.version != year &&
                                            <DropdownItem onClick={() => { this.prepareTimeMachineTravel(year) }} key={`time-machine-${year}`}>
                                                {year}
                                            </DropdownItem>
                                        )
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Fragment>
        )
    }

    render2020Version(routes) {
        let navLinks = [];
        Object.keys(routes).map((year) => {
            let navLink = new NavLink(`${year}`, `/${year}`, routes[year]);
            navLinks.push(navLink);
        });

        const timeMachineItem = (
            <React.Fragment>
                <i className='fa fa-history'/>&nbsp;
                Time Machine
            </React.Fragment>
        );

        return (
            <Fragment>
                <TimeMachineModal show={this.state.modal.show} year={this.state.modal.year} closeModal={this.closeModal}/>
                <div className="header py-4">
                    <div className="container">
                        <div className="d-flex">

                            <a id="header-brand-id" className="header-brand" href="/">
                                <i className='fa fa-heart heartIcon'/>
                                &nbsp;Para Fernanda
                            </a>

                            <div className="d-flex order-lg-2 ml-auto">
                            </div>

                            <a onClick={this.toggle} className="header-toggler d-lg-none ml-3 ml-lg-0" data-toggle="collapse" data-target="#headerMenuCollapse">
                                <span className="header-toggler-icon"></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="osita-navbar">
                    <div className={`header collapse d-lg-flex p-0 ${(this.state.isOpen) ? 'show' : ''}`}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg order-lg-first">
                                    <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                                        {
                                            navLinks.map((n, index) => (
                                                <NavItemRender navLink={n} key={index}/>
                                            ))
                                        }

                                        <TablerNav.Item hasSubNav value={timeMachineItem}>
                                            {
                                                this.props.availableVersions.map((year, idx) => 
                                                    this.props.version != year &&
                                                    <a className='dropdown-item' onClick={() => { this.prepareTimeMachineTravel(year) }} key={`time-machine-${year}`}>
                                                        <i className={`fa fa-hourglass-${(idx)%2 ? 'end' : 'start'}`}/>&nbsp;
                                                        {year}
                                                    </a>
                                                )
                                            }
                                        </TablerNav.Item>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
        availableVersions: state.versioning.availableVersions,
        routes: state.routing.routes,
    }
}

export default connect(mapStateToProps, null)(Header);