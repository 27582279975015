export function getRandomColor() {
    let colors = [
        'succes',
        'info',
        'warning',
        'primary',
        'secondary',
        'danger'
    ];
    let option = Math.random() * colors.length;
    option = Math.floor(option);
    return colors[option];
}

export function getRandomHEXColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
}

export function shuffleArray(array) {
    var i,
    j,
    temp;
    for (i = array.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}