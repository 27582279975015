import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageFetcherService from './../../utils/ImageFetcherService';
import GeneralSitePage from '../../components/GeneralSitePage';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { getRandomColor } from './../../utils/utils';

class Cumpleanios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2018/cumpleanios/bd-1.jpg', '2018/cumpleanios/bd-2.jpg']);
        this.setState({
            images: images,
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="cumpleanios-2018">
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.images[0]} />
                </div>
                <p>
                    Buenos días flaquita, primero quiero dejarte un pequeño video que me gusta mucho; es para desearte feliz cumpleaños:
                    <br/>
                </p>
                <Card body outline color={getRandomColor()}>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe width="380" height="112" src="https://www.youtube.com/embed/j9HPNMBuHSk" allowfullscreen></iframe>
                    </div>
                    <CardBody>
                        <CardTitle>Feliz cumpleaños chiquita!</CardTitle>
                    </CardBody>
                </Card>
                <p>
                    Quiero desearte un feliz cumpleaños mi chiquita hermosa!. Y agradecerte por estar conmigo este año, porque también hace
                    poquito fue nuestro aniversario. Y te amo, te amo mucho, te amo con todo mi corazón. Eres mi vida, mi novia, mi hamburguesita,
                    mi osita, mi koala, mi pastelito y más importante, mi esposa. Gracias por quedarte con este gordito, y dejar que esté en tu vida, y dejar que lo dejen ir a tu casa para ver películas apapchados.
                    <br /><br />
                    También espero verte mañana (martes) para ir por tu regalo, el cual
                    espero te guste mucho porque te lo regalo con todo mi amor y todo mi cariño, para que te cuide cuando yo no puedo cuidarte
                    en las noches.
                    <br /><br />
                    Te amo con todo mi corazón flaquita, feliz cumpleaños y muchas felicidades, y gracias por ser la mejor esposa
                    del mundo. Quiero celebrar contigo muchos, muchos, muchos cumpleaños juntos, con pastelito, y besitos de papitas.
                    <br /><br />
                    También se me olvidaba, había tomado estás fotos, y como son las últimas que nos tomamos juntitos, me gusto ponerlas, y
                    dejar está al final.
                </p>  

                <div className="text-center">
                    <img className="img-fluid" src={this.state.images[1]}/>
                </div>
            </div>
        );        
    }
}

Cumpleanios.defaultProps = {
    content: [
        {
            type: "image",
            value: '2018/cumpleanios/bd-1.jpg',
            title: 'Haciendo caritas',
            description: 'Porque nos amamos'
        },
        {
            type: "text",
            value: "Buenos días flaquita, primero quiero dejarte un pequeño video que me gusta mucho; es para desearte feliz cumpleaños:"
        },
        {
            type: "video",
            value: {
                'title': 'Las mañanitas',
                'avatarName': 'Pedro Infante',
                'avatarURL': 'https://i.scdn.co/image/ccf922f75bb15f90b619cbb55f2f7f9c2ccce836',
                'videoURL': 'https://www.youtube.com/embed/7fpd4N5T5qw'
            },
        },
        {
            type: "text",
            value: "Quiero desearte un feliz cumpleaños mi chiquita hermosa!. Y agradecerte por estar conmigo este año, porque también hace poquito fue nuestro aniversario. Y te amo, te amo mucho, te amo con todo mi corazón. Eres mi vida, mi novia, mi hamburguesita, mi osita, mi koala, mi pastelito y más importante, mi esposa. Gracias por quedarte con este gordito, y dejar que esté en tu vida, y dejar que lo dejen ir a tu casa para ver películas apapchados."
        },
        {
            type: "text",
            value: "También espero verte mañana (martes) para ir por tu regalo, el cual espero te guste mucho porque te lo regalo con todo mi amor y todo mi cariño, para que te cuide cuando yo no puedo cuidarte en las noches.",
        },
        {
            type: "text",
            value: "Te amo con todo mi corazón flaquita, feliz cumpleaños y muchas felicidades, y gracias por ser la mejor esposa del mundo. Quiero celebrar contigo muchos, muchos, muchos cumpleaños juntos, con pastelito, y besitos de papitas.",
        },
        {
            type: "text",
            value: "También se me olvidaba, había tomado estás fotos, y como son las últimas que nos tomamos juntitos, me gusto ponerlas, y dejar está al final.",
        },
        {
            type: "image",
            value: '2018/cumpleanios/bd-2.jpg',
            title: 'Haciendo más caritas',
            description: 'Porque nos amamos'
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Cumpleanios);