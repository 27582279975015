import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Cumpleanios extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Cumpleanios.defaultProps = {
    content: [
        {
            type: "image",
            value: '2023/cumpleanios/durmimono.jpg',
            title: 'Dormimono',
            description: 'Te amo mucho monito dormilón.'
        },
        {
            type: "header",
            value: `¡Feliz cumpleaños esposa hermosa!`
        },
        {
            type: "text",
            value: `Hola esposa. Te amo mucho y quiero desearte un feliz cumpleaños. Quiero agradecerte por siempre apoyarme y por ser una esposa amorosa y cariñosa siempre. No quiero que el mono se sienta triste en su cumpleaños. Quiero ver a mi esposa feliz, siendo loquito y comiendo su pastel de arcoiris que sabe bien feo.`
        },
        {
            type: "text",
            value: `Espero que mono haya recibido y esté feliz con la lista de sus productos favoritos que tanto me compartió en sus sueños. Me gusta ver feliz al mono, y escuchar sus ruidos locos de emoción.`
        },
        {
            type: "text",
            value: `Ya quiero verte, para darte tus abrazos de osos con manita en pompi para celebrar que mono vino al mundo y ahora hace muy feliz a esposo todos los días con su amor, sus locuras, sus cariñitos y sus cuidados. `
        },
        {
            type: "text",
            value: `Quiero que sepas que te amo mucho, que necesito a mi esposa toda la vida, y poder hacerte feliz todos los días, con detalles bonitos como masajitos de patitas, piojito, y gomitas sin que el mono me las pida para que tenga sorpresas. Quiero que estes a mi lado, para que puedas dormir bonito todas las noches, y mi piojito y masajito te ayuden a descansar.`
        },
        {
            type: "text",
            value: `Te amo mucho esposa, y espero que tengas un feliz cumpleaños. Y perdona a esposo por siempre capturar tus peores momentos.`
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Cumpleanios);
