import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { getRandomColor } from './../../utils/utils';
import { Page } from 'tabler-react';
import BlogCardVideo from '../../components/BlogCardVideo';

class Canciones extends Component {
    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return this.render2020Version();
    }

    render2017Version() {
        return (
            <div className="canciones-2017">
                <h1 className="text-center">Te Amo Fernanda</h1>
                <hr/>
                {
                    this.props.canciones.map((song, index) => 
                        <Card key={index} body outline color={getRandomColor()}>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe src={song.videoURL} allowfullscreen></iframe>
                            </div>
                            <CardBody>
                                <CardTitle>{`${index+1}. ${song.title}`}</CardTitle>
                            </CardBody>
                        </Card>
                    )
                }
            </div>
        );
    }

    render2020Version() {
        return (
            <div className='canciones2020'>
                <Page.Header title="Te Amo Fernanda"/>
                {
                    this.props.canciones.map((c, i) => 
                        <BlogCardVideo key={`songs-key-${i}`} data={c}/>
                    )
                }
            </div>
        );
    }
}

Canciones.defaultProps = {
    'canciones': [
        {
            'title': 'There There',
            'avatarName': 'Radiohead',
            'avatarURL': 'https://i.scdn.co/image/afcd616e1ef2d2786f47b3b4a8a6aeea24a72adc',
            'videoURL': 'https://www.youtube.com/embed/RNp7YRHSwvc'
        },
        {
            'title': 'I Might Be Wrong',
            'avatarName': 'Radiohead',
            'avatarURL': 'https://i.scdn.co/image/afcd616e1ef2d2786f47b3b4a8a6aeea24a72adc',
            'videoURL': 'https://www.youtube.com/embed/mQ3B1gbSZAY'
        },
        {
            'title': 'The Killing Moon',
            'avatarName': 'Echo And The Bunnymen',
            'avatarURL': 'https://i.scdn.co/image/2bff77f9ef0e1bbb46d40607f15893e4f09403d5',
            'videoURL': 'https://www.youtube.com/embed/Q9inQGYROVU'
        },
        {
            'title': 'Químicos',
            'avatarName': 'Little Jesus',
            'avatarURL': 'https://i.scdn.co/image/98ba83bb403bce0912c2c419428ed67dffd4c8cb',
            'videoURL': 'https://www.youtube.com/embed/WcBtxppp7rw'
        },
        {
            'title': 'I Will Possess Your Heart',
            'avatarName': 'Death Cab For Cutie',
            'avatarURL': 'https://i.scdn.co/image/6b44348b3a5828eb6815d2030e2229fd6ab84f2b',
            'videoURL': 'https://www.youtube.com/embed/tFnYlkDtz00'
        },
        {
            'title': 'By Your Side',
            'avatarName': 'Alice Jemima',
            'avatarURL': 'https://i.scdn.co/image/c552f266f33c29c0c28fd012ccdb85c55d1fa977',
            'videoURL': 'https://www.youtube.com/embed/u5g3Kqn88FQ'
        },
        {
            'title': 'Please, Please, Please, Let Me Get What I Want',
            'avatarName': 'The Smiths',
            'avatarURL': 'https://i.scdn.co/image/bd4c7f5ff2c5c4385604e60c71eac1dd498ddbd9',
            'videoURL': 'https://www.youtube.com/embed/74BA7CUrGYw'
        },
        {
            'title': 'Venus',
            'avatarName': 'AIR',
            'avatarURL': 'https://i.scdn.co/image/f54692cd46cf35bf425f0e2bba5e002b7928a661',
            'videoURL': 'https://www.youtube.com/embed/vWh3uK9qY-A'
        },
        {
            'title': 'Te Necesito',
            'avatarName': 'Luis Miguel',
            'avatarURL': 'https://i.scdn.co/image/d9854aae131602d7a5b07cc980a150730e0114a3',
            'videoURL': 'https://www.youtube.com/embed/9WpHRoHX6z4'
        },
        {
            'title': 'ラビリンス',
            'avatarName': 'MONDO GROSSO',
            'avatarURL': 'https://i.scdn.co/image/ab67616d00001e021607d3aa3a69ca0e1ffbe26b',
            'videoURL': 'https://www.youtube.com/embed/_2quiyHfJQw'
        },
        {
            'title': 'Just Like Heaven',
            'artitsName': 'The Cure',
            'avatarURL': 'https://i.scdn.co/image/2cb2e14783685fd3d27006891aaaa35fc53cd82d',
            'videoURL': 'https://www.youtube.com/embed/g6x0TUjtmJw'
        },
        {
            'title': 'I Hope You Die',
            'avatarName': 'Molly Nilsson',
            'avatarURL': 'https://i.scdn.co/image/fb010722d78eeac9f1c511e734b26d8b4fbdd260',
            'videoURL': 'https://www.youtube.com/embed/Azhy8abJ2_8'
        },
        {
            'title': 'Renaissance Affair',
            'avatarName': 'Hooverphonic',
            'avatarURL': 'https://i.scdn.co/image/8b259f2ebbc99a2abdf39990d5df618f58ede0d9',
            'videoURL': 'https://www.youtube.com/embed/pOOvF7I4M5A'
        },
        {
            'title': 'Weird Fishes / Arpeggi',
            'avatarName': 'Radiohead',
            'avatarURL': 'https://i.scdn.co/image/afcd616e1ef2d2786f47b3b4a8a6aeea24a72adc',
            'videoURL': 'https://www.youtube.com/embed/Q888PBtrWc0'
        },
        {
            'title': 'Coffe & TV',
            'avatarName': 'Blur',
            'avatarURL': 'https://i.scdn.co/image/b3fa1dd751944a695df05624d4449d2862dc8fba',
            'videoURL': 'https://www.youtube.com/embed/IqfmMjejHZw'
        },
        {
            'title': 'Tender',
            'avatarName': 'Blur',
            'avatarURL': 'https://i.scdn.co/image/b3fa1dd751944a695df05624d4449d2862dc8fba',
            'videoURL': 'https://www.youtube.com/embed/-T8LMZhz6FQ'
        },
        {
            'title': 'Ong Ong',
            'avatarName': 'Blur',
            'avatarURL': 'https://i.scdn.co/image/b3fa1dd751944a695df05624d4449d2862dc8fba',
            'videoURL': 'https://www.youtube.com/embed/Shja1fzmPhU'
        },
        {
            'title': 'Deja Que Salga La Luna',
            'avatarName': 'José Alfredo Jimenez',
            'avatarURL': 'https://i.scdn.co/image/f3fb5804899e75a41ef43bf6cf9afd2f65350deb',
            'videoURL': 'https://www.youtube.com/embed/ZH4aYbJZJZc'
        },
        {
            'title': 'Carter & Cash',
            'avatarName': 'Tor Miller',
            'avatarURL': 'https://i.scdn.co/image/7f732147eee7d7762c19b0eb6ccc0269223c26ed',
            'videoURL': 'https://www.youtube.com/embed/gHlUvPCbnwE'
        },
        {
            'title': 'I Promise',
            'avatarName': 'Radiohead',
            'avatarURL': 'https://i.scdn.co/image/afcd616e1ef2d2786f47b3b4a8a6aeea24a72adc',
            'videoURL': 'https://www.youtube.com/embed/kR7HRFE8JbY'
        },
        {
            'title': "Touch Me I'm Going To Scream Pt. 2",
            'avatarName': 'My Morning Jacket',
            'avatarURL': 'https://i.scdn.co/image/f2f7043f9db2eee94a62df3d3ecf929731ec2e35',
            'videoURL': 'https://www.youtube.com/embed/j3PciCWIGLE'
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Canciones);