import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageFetcherService from './../../utils/ImageFetcherService';
import GeneralSitePage from '../../components/GeneralSitePage';

class Febrero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: '',
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['index/5.jpg']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="febrero-2018">
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.image} />
                </div>
                <p>
                    Buenos días flaquita, hace casi un año fue un día muy bonito. El 16 de febrero tú y yo nos conocimos y dimos nuestros primeros besitos 💏, nuestros primeros abrazos 🐻🐨 y nuestros primeros ojitos 👀.<br/><br/>
                    Y aunque aún faltan dos días, hoy es 14 de febrero. Y quería escribirte algo bonito porque ya casi es un año de conocernos y de estar juntitos. Eres mi niña hermosa flaquita, y siempre, siempre te voy a amar. Perdón por las peleas y hacerte enojar :( <br/><br/>
                    Y en esta cartita solo quería poner algunas cosas bonitas que hemos vivido:
                </p>
                <ul>
                    {
                        this.props.things.map((thing, index) => 
                            <li key={index}>
                                <i className='fa fa-heart heartIcon'/> &nbsp; {thing}
                            </li>
                        )
                    }
                </ul>
            </div>
        );
    }
}

Febrero.defaultProps = {
    'things': [
        'Conocernos y robarte un besito.',
        'Ir a comer pastelito.',
        'Que me acompañaras a la escuela y nos hundieramos en una banca.',
        'Ir a Paseo Acoxpa, comprar galletitas y pedirte que fueras mi novia.',
        'Ponerte un anillo de compromiso de cheeto.',
        "Ir al parquecito juntos a ver animalitos, apapacharnos y luego ir a comprar cositas a McDonald's.",
        'Ir al parquecito de Tulyehualco, y en mitad del camino detenerte para darte otro anillo de compromiso de duendecito.',
        'Hacer cositas juntos.',
        'Esperarte cuando voy por ti, verte a los ojitos, verte sonreir, me encanta y cada que lo haces me vuelves loquito.',
        'Darte un abrazo y un besito cuando fuiste a las hamburguesas del enterrador.',
        'Dormir juntitos como ositos, y cuidandonos.',
        'Cuando viniste a mi casa y conociste a Boran.',
        'Cuando salimos por un helado y la gordita nos acompaño.'
    ],
    content: [
        {
            type: "image",
            value: 'index/5.jpg',
            title: 'Un besito',
            description: 'De mordidita'
        },
        {
            type: "text",
            value: "Buenos días flaquita, hace casi un año fue un día muy bonito. El 16 de febrero tú y yo nos conocimos y dimos nuestros primeros besitos 💏, nuestros primeros abrazos 🐻🐨 y nuestros primeros ojitos 👀."
        },
        {
            type: "text",
            value: "Y aunque aún faltan dos días, hoy es 14 de febrero. Y quería escribirte algo bonito porque ya casi es un año de conocernos y de estar juntitos. Eres mi niña hermosa flaquita, y siempre, siempre te voy a amar. Perdón por las peleas y hacerte enojar :("
        },
        {
            type: "text",
            value: "Y en esta cartita solo quería poner algunas cosas bonitas que hemos vivido:"
        },
        {
            type: "list",
            value: [
                'Conocernos y robarte un besito.',
                'Ir a comer pastelito.',
                'Que me acompañaras a la escuela y nos hundieramos en una banca.',
                'Ir a Paseo Acoxpa, comprar galletitas y pedirte que fueras mi novia.',
                'Ponerte un anillo de compromiso de cheeto.',
                "Ir al parquecito juntos a ver animalitos, apapacharnos y luego ir a comprar cositas a McDonald's.",
                'Ir al parquecito de Tulyehualco, y en mitad del camino detenerte para darte otro anillo de compromiso de duendecito.',
                'Hacer cositas juntos.',
                'Esperarte cuando voy por ti, verte a los ojitos, verte sonreir, me encanta y cada que lo haces me vuelves loquito.',
                'Darte un abrazo y un besito cuando fuiste a las hamburguesas del enterrador.',
                'Dormir juntitos como ositos, y cuidandonos.',
                'Cuando viniste a mi casa y conociste a Boran.',
                'Cuando salimos por un helado y la gordita nos acompaño.'
            ]
        }
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Febrero);