import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Navidad extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Navidad.defaultProps = {
    content: [
        {
            type: "image",
            value: '2021/bustiloco.png',
            title: 'Juntos con Bustani',
            description: 'El día que Bustani quizo que nos tomaramos de la mano'
        },
        {
            type: "text",
            value: `Hola mono, ¡Feliz Navidad!`
        },
        {
            type: "text",
            value: `Esposo te ama mucho, y te manda abrazos y cariñitos, para que estés calentita y tengas una muy feliz Navidad.`
        },
        {
            type: "text",
            value: `Este año no pude estar en Navidad con mono, pero en año nuevo se que estaremos juntitos, llenándonos de apapachos y mimandonos como esposos.`
        },
        {
            type: "text",
            value: `Se que esta es la primera vez en el año que actualizó esta página. Perdón por eso monito, pero no me había dado tiempo. Llevo tanto sin escribir aquí que me tomo una hora volver a entender el código con el que hice esta página.`
        },
        {
            type: "text",
            value: `Pero otra vez te escribo aquí, para decirte que siempre serás el mejor regalo de Navidad del mundo.`
        },
        {
            type: "text",
            value: `Con tus ruiditos de mono, tus frases chistosas, tus locuras cuando eres sonámbulo y tus calcetas locas.`
        },
        {
            type: "text",
            value: `Te amo mucho mono, y espero tengas una Feliz Navidad, aunque oso no esté a tu lado hoy, quiero que sepas que te extraño mucho siempre y que te amo con todo mi corazón esposa hermosa. Gracias por seguir junto a oso, apoyarlo y amarlo mucho todos los días.`
        },
        {
            type: "text",
            value: `Eres mi todo monito loco y te amo con todo mi corazón.`
        },
        {
            type: "header",
            value: `¡Feliz Navidad mono loco!`
        },
        {
            type: "text",
            value: `Mañana voy con esposa, para poder estar encimados, y juntitos, viendo películas  rascuachas mientras nos apapachamos para no tener frío con cobijitas.`
        },
        {
            type: "text",
            value: `¡Te amo mucho esposa!`
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Navidad);