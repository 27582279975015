import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Navidad extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Navidad.defaultProps = {
    content: [
        {
            type: "image",
            value: '2020/platanito.png',
            title: 'Platanito',
            description: 'Osita durmiendo como platanito después de ver pasteles.'
        },
        {
            type: "header",
            value: "¡Feliz Navidad esposa!",
            size: 4,
        },
        {
            type: "text",
            value: "Gracias por dejarme pasar este día a tu lado en tu casita. Te amo mucho esposa y me hace muy feliz poder estar a tu lado hoy, y saber que podemos estar juntitos en la noche, abrazados y viendo películas usando pijamitas."
        },
        {
            type: "text",
            value: "Use la foto que te tome cuando duermes como platanito, porque me hace muy feliz verte durmiendo, y que siempre eres chistosa cuando duermes, y sobre todo muy amorosa, porque siempre me pides cosas chistosas, como tu chocolate o tus bubulubus."
        },
        {
            type: "text",
            value: "Te amo mucho, y quiero que hoy nos acurruquemos y durmamos como platanitos sin movernos."
        },
        {
            type: "text",
            value: "Eres el amor de mi vida, y espero que aunque este regalo es sencillo, siempre te guste ver lo que esposo te escribe, porque te ama mucho."
        },
        {
            type: "text",
            value: "También me siento triste porque se me olvido como se llama el perfume que te gusto, y me dio pena preguntarte mi amor, y no supe que regalarte para Navidad. Por favor dile a osito que te gustaría de regalo de Navidad para que pueda pedirlo."
        },
        {
            type: "text",
            value: "Te amo, y quiero agradecerte por amarme mucho y llenarme de mucho amor siempre. "
        },
        {
            type: "header",
            value: "Gracias por invitarme a cenar contigo esposita, porque te amo mucho.",
            size: 4,
        },
        {
            type: "text",
            value: "Ahora te puse a Mijares porque dices que puedo cantar extriñido como él."
        },
        {
            type: "video",
            value: {
                'title': 'Feliz Navidad',
                'avatarName': 'Mijares',
                'avatarURL': 'https://i.scdn.co/image/5c3fffda0941b4d79d32c8c5c978f677d38231b4',
                'videoURL': 'https://www.youtube.com/embed/E9nvkZFN6PA'
            },
        }
    ],
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Navidad);