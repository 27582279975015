import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageFetcherService from './../../utils/ImageFetcherService';
import GeneralSitePage from '../../components/GeneralSitePage';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { getRandomColor } from './../../utils/utils';

class AnioNuevo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: [],
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2018/anionuevo/boton.jpg']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="cumpleanios-2018">
                <div className="text-center">
                    <img style={{width: 220, heigth: 440}} className="img-fluid banner" src={this.state.image} />
                </div>
                <p>
                    Buenas noches mi amor 😭💚💏💏, antes que nada, feliz Año Nuevo. 
                    Quiero darte las gracias por este año que volvemos a pasar juntos, y sobre todo por siempre querer estar conmigo y seguir a lado de tu gordito, por no dejarlo, y por cuidarlo siempre y sobre todo por ser su esposa🤵🏻👰🏻. 
                    También quiero darte gracias por todos los momentos bonitos que hemos pasado, desde que nos conocimos hasta ahora, y quiero que cada año recuerdes todos esos momentos 🐻🐨, porque son muy bonitos y que el siguiente año tengamos mas momentos bonitos aún.
                    También perdona a tu osito, por las veces que hemos peleado, y espero que los momentos que hemos tenido compensen los malos ratos.
                    Muchas gracias amor, por estar con tu gordito, por dejarme estar este año no solo contigo, si no con tu familia y los bebés. Gracias por quererme como esposo y estar convencida de que seremos una familia que se ama, con muchos gorditos 🐶🐶🐱🐶 hermosos.
                    Te amo mucho esposa hermosa, 👰🏻🤵🏻, gracias por todo lo que has hecho este año por mi y por ser lo mejor de mi vida.
                    Te amoooo 😭💚💏💏💏.
                    <br/>
                    Feliz Año Nuevo mi amor, gracias por estar a mi lado y hacer que estos años sean los mejores de mi vida contigo a mi lado. TE AMO!
                </p>
                <Card body outline color={getRandomColor()}>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe width="380" height="112" src="https://www.youtube.com/embed/gnZ-hNLcnr0?start=65" allowfullscreen></iframe>
                    </div>
                    <CardBody>
                        <CardTitle style={{fontSize: 14}}>Te dejo una canción amor💚</CardTitle>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

AnioNuevo.defaultProps = {
    content: [
        {
            type: "image",
            value: '2018/anionuevo/boton.jpg',
            title: 'Botoncito',
            description: 'Adueñandose del carro'
        },
        {
            type: "text",
            value: "Buenas noches mi amor 😭💚💏💏, antes que nada, feliz Año Nuevo. Quiero darte las gracias por este año que volvemos a pasar juntos, y sobre todo por siempre querer estar conmigo y seguir a lado de tu gordito, por no dejarlo, y por cuidarlo siempre y sobre todo por ser su esposa🤵🏻👰🏻. También quiero darte gracias por todos los momentos bonitos que hemos pasado, desde que nos conocimos hasta ahora, y quiero que cada año recuerdes todos esos momentos 🐻🐨, porque son muy bonitos y que el siguiente año tengamos mas momentos bonitos aún. También perdona a tu osito, por las veces que hemos peleado, y espero que los momentos que hemos tenido compensen los malos ratos. Muchas gracias amor, por estar con tu gordito, por dejarme estar este año no solo contigo, si no con tu familia y los bebés. Gracias por quererme como esposo y estar convencida de que seremos una familia que se ama, con muchos gorditos 🐶🐶🐱🐶 hermosos. Te amo mucho esposa hermosa, 👰🏻🤵🏻, gracias por todo lo que has hecho este año por mi y por ser lo mejor de mi vida. Te amoooo 😭💚💏💏💏.",
        },
        {
            type: "text",
            value: "Feliz Año Nuevo mi amor, gracias por estar a mi lado y hacer que estos años sean los mejores de mi vida contigo a mi lado. TE AMO!"
        },
        {
            type: "video",
            value: {
                'title': 'Te dejo una canción amor 💚',
                'avatarName': 'Something Changed - Pulp',
                'avatarURL': 'https://i.scdn.co/image/51de13e6f54f873c958960c63d4fb19ba453f7b1',
                'videoURL': 'https://www.youtube.com/embed/gnZ-hNLcnr0?start=65'
            },
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(AnioNuevo);