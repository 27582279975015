import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class AnioNuevo extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

AnioNuevo.defaultProps = {
    content: [
        {
            type: "image",
            value: '2022/anio-nuevo/anio.jpg',
            title: 'Durmiendo con Bustani',
            description: 'Me gusta mucho cuidarte en las noches, aunque Busti este de encimoso.'
        },
        {
            type: "text",
            value: `Hola mi amor, quiero desearte un feliz año nuevo.`
        },
        {
            type: "text",
            value: `Te amo mucho, y quiero agradecerte por estar otro año más a mi lado. También quiero que me perdones por todas las veces que te hago llorar. Te amo mucho, y quiero estar con monito toda la vida, amo ver a mi monito loco y feliz.`
        },
        {
            type: "text",
            value: `Aunque este año nuevo no lo pase a tu lado, quiero que sepas que te amo mucho y que mañana estare con esposa, encimados y abrazados para ver peliculas y comer loqueras de la tienda.`
        },
        {
            type: "text",
            value: `Gracias por cuidarme siempre, por abrazarme, llenarme de besitos, robarme chocolates y contarme locuras del chupacabras. Cada día me haces muy feliz, y cada día quiero estar con mono, para conocer su nueva locura y estar felices siempre.`
        },
        {
            type: "text",
            value: `Te amo mucho mi amor, y quiero pasar todos los años de mi vida a tu lado.`
        },
        {
            type: "text",
            value: `Te amo esposa.`
        },
        {
            type: "header",
            value: `¡Feliz Año Nuevo!`,
            size: 4,
        },
    ],
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(AnioNuevo);