import React, { Component, Fragment } from 'react';
import { CardColumns } from 'reactstrap';
import { GalleryCard } from 'tabler-react';

import GalleryCardImage from './GalleryCardImage';
import BlogCardVideo from './BlogCardVideo';

import { Header } from 'tabler-react'; 

import { getRandomColor, getRandomHEXColor } from './../utils/utils';

import ImageFetcherService from '../utils/ImageFetcherService';

export default class GeneralUIComponent extends Component {

    render() {
        switch(this.props.component.type) {
            case 'text':
                return this.getTextComponent();
            case 'image':
                return this.getImageComponent();
            case 'video':
                return <BlogCardVideo data={this.props.component.value}/>
            case 'list':
                return this.getListComponent();
            case 'header':
                return (<Header size={this.props.component.size}>{this.props.component.value}</Header>);
            default:
                return (<div></div>);
        }
    }

    getListComponent() {
        return (
            <ul>
                {
                    this.props.component.value.map((it, idx) =>
                        <li key={`list-component-${idx}`}>
                            <i className='fa fa-heart' style={{color: getRandomHEXColor()}}/> &nbsp;{it}
                        </li>
                    )
                }
            </ul>
        );
    }

    getTextComponent() {
        return (
            <Fragment>
                <p>{this.props.component.value}</p>
            </Fragment>
        );
    }

    getImageComponent() {
        const service = new ImageFetcherService();
        let images = service.fetchImages([this.props.component.value, this.props.component.alt]);
        const image = images[0];
        const alt = images[1];

        return (
            <CardColumns>
                <GalleryCard className="hidden-card"/>
                <GalleryCardImage 
                    url={image}
                    avatarURL={this.props.component.alt ? alt : image}
                    title={this.props.component.title}
                    description={this.props.component.description}
                />
                <GalleryCard className="hidden-card"/>
            </CardColumns>
        );
    }

}

GeneralUIComponent.defaultProps = {
    component: {
        type: "text",
        value: ""
    }
}