import React, { Component } from 'react';

export default class BlogCardVideo extends Component {

    render() {
        return (
            <div className="card card-aside">
                <div className="card-aside-column embed-responsive embed-responsive-16by9">
                    <iframe src={this.props.data.videoURL} allowfullscreen></iframe>
                </div>
                <div className="card-body d-flex flex-column">
                    <h4>
                        <a>{this.props.data.title}</a>
                    </h4>
                    <div className="text-muted">
                        {this.props.data.subtitle}
                    </div>
                    <div className="d-flex align-items-center pt-5 mt-auto">
                        <div className="avatar avatar-md mr-3" style={{ 'backgroundImage': `url(${this.props.data.avatarURL})` }}>
                        </div>
                        <div>
                            <a className="text-default">{this.props.data.avatarName}</a>
                            <small className="d-block text-muted"></small>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

BlogCardVideo.defaultProps = {
    "data": {
        "videoURL": "",
        "title": "",
        "subtitle": "",
        "avatarURL": "",
        "avatarName": "",
    }
}