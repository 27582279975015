import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { getRandomColor } from './../../utils/utils';

class Navidad extends Component {
    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div>
                <p>
                    Hola mi amor, buenas noches.<br />
                    Esta página ha sido tu regalo de Navidad desde el año pasado y quiero que sea un regalo que puedas ver siempre. Un regalo que te recuerde el tiempo que hemos pasado juntos, y todo lo bonito que me haces sentir mi chiquita hermosa. 
                    Te amo mucho 😭💚👰🏻🤵🏻, y gracias por todos los días tan hermosos que hemos pasado juntos, como esposos 👰🏻🤵🏻, como ositos 🐻👣🐨, y  sobre todo, cada día que hemos pasado juntos desde que nos conocimos. Te amo mucho y es lo más bonito del mundo que seas mi esposa, y que quieras seguir a lado de este gordito que te ama con todo su corazón. 
                    Algo que si quiero agradecerte mucho es que me dejes estar contigo, con los bebés, y por darme a un nuevo bebé loquito que se llama Boton. También por dejarme estar contigo y tu familia y mimarme siempre, por siempre cuidarme y por ser el mejor regalo de navidad cada año mi amor. Porque eres lo mejor de mi vida, y mi todo.
                    Te amo mucho esposa 😭💚💏💏, feliz navidad mi chiquita, te mando un abrazo muy grande de oso 🐨🐻, y otro de gorilita porque te amoooooo 🦍👣🐨.
                    <br />
                    Antes que nada, quiero decirte amor, que espero que tu plantita que fue tu regalo de Navidad te haya gustado mucho. Y que aunque yo no la escogí, te la dí con mucho cariño mi esposa hermosa 🤵🏻💏💏💏💏💏💏👰🏻.
                </p>
                <Card body outline color={getRandomColor()}>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe width="380" height="112" src="https://www.youtube.com/embed/M2b6O30TtBo" allowfullscreen></iframe>
                    </div>
                    <CardBody>
                        <CardTitle>Ya por último amor, quiero decirte que quiero ser yo tu Santa Claus y tu regalito 💚</CardTitle>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

Navidad.defaultProps = {
    content: [
        {
            type: "text",
            value: "Hola mi amor, buenas noches.",
        },
        {
            type: "text",
            value: "Esta página ha sido tu regalo de Navidad desde el año pasado y quiero que sea un regalo que puedas ver siempre. Un regalo que te recuerde el tiempo que hemos pasado juntos, y todo lo bonito que me haces sentir mi chiquita hermosa. Te amo mucho 😭💚👰🏻🤵🏻, y gracias por todos los días tan hermosos que hemos pasado juntos, como esposos 👰🏻🤵🏻, como ositos 🐻👣🐨, y sobre todo, cada día que hemos pasado juntos desde que nos conocimos. Te amo mucho y es lo más bonito del mundo que seas mi esposa, y que quieras seguir a lado de este gordito que te ama con todo su corazón. Algo que si quiero agradecerte mucho es que me dejes estar contigo, con los bebés, y por darme a un nuevo bebé loquito que se llama Boton. También por dejarme estar contigo y tu familia y mimarme siempre, por siempre cuidarme y por ser el mejor regalo de navidad cada año mi amor. Porque eres lo mejor de mi vida, y mi todo. Te amo mucho esposa 😭💚💏💏, feliz navidad mi chiquita, te mando un abrazo muy grande de oso 🐨🐻, y otro de gorilita porque te amoooooo 🦍👣🐨."
        },
        {
            type: "text",
            value: "Antes que nada, quiero decirte amor, que espero que tu plantita que fue tu regalo de Navidad te haya gustado mucho. Y que aunque yo no la escogí, te la dí con mucho cariño mi esposa hermosa 🤵🏻💏💏💏💏💏💏👰🏻."
        },
        {
            type: "video",
            value: {
                'title': 'Ya por último amor, quiero decirte que quiero ser yo tu Santa Claus y tu regalito 💚',
                'avatarName': 'Santa Claus Llegó a la Ciudad - Luis Miguel',
                'avatarURL': 'https://i.scdn.co/image/d9854aae131602d7a5b07cc980a150730e0114a3',
                'videoURL': 'https://www.youtube.com/embed/M2b6O30TtBo'
            },
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Navidad);