import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Navidad extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Navidad.defaultProps = {
    content: [
        {
            type: "image",
            value: '2022/navidad/navidad.jpg',
            title: 'Orinando a los flamingos',
            description: 'El día que monito me destruyo mi chancla'
        },
        {
            type: "text",
            value: `Hola mono, ¡Feliz Navidad!`
        },
        {
            type: "text",
            value: `Te amo mucho, gracias por dejarme pasar la otra Navidad a tu lado. Esta es la tercera vez que pasamos juntos esta fecha y me hace muy feliz poder estar a tu lado.`
        },
        {
            type: "text",
            value: `Monito quizás no tendría regalo en el árbol porque se agotaron sus loqueras, pero espero que despertar con osito en la camita te haga feliz.`
        },
        {
            type: "text",
            value: `Sabes que me gusta despertar con tus locuras, y espero que podamos buscar algo para que el día de reyes monito pueda despertar con una loquera debajo del árbol.`
        },
        {
            type: "text",
            value: `Te amo esposa, mucho. Siempre serás lo más bonito del mundo, y tus besitos y abrazos serán siempre el mejor regalo de Navidad.`
        },
        {
            type: "text",
            value: `Gracias por dejarme estar otra vez a tu lado. Quiero llenarte de abrazos y que estés feliz con esposo.`
        },
        {
            type: "text",
            value: `Hoy quiero que monito se aloque y se ponga pijamita con oso para pasar la Navidad calientitos y dándonos abrazos con masajito. Necesito mucho a esposa y quiero estar a tu lado toda la vida.`
        },
        {
            type: "header",
            value: `¡Feliz Navidad monito loco y amoroso!`
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Navidad);