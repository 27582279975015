import React, {Component, Fragment} from 'react';
import { Nav } from 'tabler-react';
const icons = [
    'fa-cat', 'fa-dog', 'fa-hippo', 'fa-horse', 'fa-paw', 'fa-fish', 'fa-kiss-wink-heart',
    'fa-heart',
];

class NavItemRender extends Component {

    constructor(props) {
        super(props);
        this.getRandomIcon = this.getRandomIcon.bind(this);
        this.isSublinkActive = this.isSublinkActive.bind(this);
    }

    render() {
        const value = (
            <React.Fragment>
                <i className={`fa ${this.getRandomIcon()}`}/>&nbsp;
                {this.props.navLink.name}
            </React.Fragment>
        );
        return (
            <Nav.Item hasSubNav value={value} active={this.props.navLink.isActive()}>
                {
                    this.props.navLink.childs.map((c, i) =>
                        <Fragment>
                            {
                                c.path !== '/' &&
                                <a href={c.path} 
                                className={`dropdown-item ${this.isSublinkActive(this.props.navLink, c)}`}
                                key={`menu-item-${i}`}
                                >
                                    <i className={`fa ${c.hasIcon ? c.icon : this.getRandomIcon()}`}/>&nbsp;
                                    {c.name}
                                </a>
                            }
                        </Fragment>
                    )
                }
            </Nav.Item>
        );
    }

    getRandomIcon = () => {
        let item = icons[Math.round(Math.random() * icons.length)];
        return item == null ? 'fa-heart' : item;
    }

    isSublinkActive = (main, sub) => {
        if (!main.isActive())
            return "";
        const route = window.location.href;
        route.indexOf(sub.route) !== -1 ? `active` : ``;
    }
}

export default NavItemRender;

NavItemRender.defaultProps = {
    navLink: {}
}