import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import ImageFetcherService from './../../utils/ImageFetcherService';
import { getRandomColor } from './../../utils/utils';
import GeneralSitePage from '../../components/GeneralSitePage';

class AnioNuevo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2017/anio-nuevo/juntitos.jpg']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="anio-nuevo-2017">
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.image} />
                </div>
                <p>
                    Hola mi amor, primero quiero mandarte muchos abrazos, y uno muy fuerte, de osito que es de Año Nuevo.<br /><br />
                    También quiero decirte que fue muy bonito conocerte este año, y seguir contigo a mi lado en este fin de año, y se que estaremos muchos mucho muchos años más juntitos.<br /><br />
                    Y por otro lado, quiero pedirte perdón por los días que no hemos estado bien, pero quiero pedirte perdón, y compensarte con miles de besitos, abrazos y buenos momentos. Y seguir estando juntos, toda toda la vida. <br /><br />
                    Eres el amor de mi vida, y siento tan bonito saber que estaremos juntos toda la vida, cómo esposos, y con nuestros gorditos, y que me hayas aceptado mis anillos, de Cheeto y también de duendecito. Gracias por estar conmigo y aceptar mis anillos y decir que si a compartir nuestras vidas. <br /><br />
                    Te amo mi amor, te amo y te amo con todo mi corazón, y quiero que sepas que siempre, toda la vida, estaré a tu lado y ahí para ti. <br /><br />
                    Te amo mi amor, eres todo para mi, y también quiero agradecerte por todas tus cartitas bonitas y con palabras bonitas, tus besitos, tus abrazos, tus sonrisas, y cada día que hemos pasado juntos. <br /><br />
                    Gracias mi amor y feliz Año Nuevo.
                </p>
                <Card body outline color={getRandomColor()}>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe src="https://www.youtube.com/embed/tJOu5ukveiQ" allowfullscreen></iframe>
                    </div>
                    <CardBody>
                        <CardTitle>No Te Apartes De Mí</CardTitle>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

AnioNuevo.defaultProps = {
    content: [
        {
            type: "image",
            value: '2017/anio-nuevo/juntitos.jpg',
            title: 'Duendecitos',
            description: '...comprometidos'
        },
        {
            type: "text",
            value: "Hola mi amor, primero quiero mandarte muchos abrazos, y uno muy fuerte, de osito que es de Año Nuevo."
        },
        {
            type: "text",
            value: "También quiero decirte que fue muy bonito conocerte este año, y seguir contigo a mi lado en este fin de año, y se que estaremos muchos mucho muchos años más juntitos."
        },
        {
            type: "text",
            value: "Y por otro lado, quiero pedirte perdón por los días que no hemos estado bien, pero quiero pedirte perdón, y compensarte con miles de besitos, abrazos y buenos momentos. Y seguir estando juntos, toda toda la vida."
        },
        {
            type: "text",
            value: "Eres el amor de mi vida, y siento tan bonito saber que estaremos juntos toda la vida, cómo esposos, y con nuestros gorditos, y que me hayas aceptado mis anillos, de Cheeto y también de duendecito. Gracias por estar conmigo y aceptar mis anillos y decir que si a compartir nuestras vidas."
        },
        {
            type: "text",
            value: "Te amo mi amor, te amo y te amo con todo mi corazón, y quiero que sepas que siempre, toda la vida, estaré a tu lado y ahí para ti."
        },
        {
            type: "text",
            value: "Te amo mi amor, eres todo para mi, y también quiero agradecerte por todas tus cartitas bonitas y con palabras bonitas, tus besitos, tus abrazos, tus sonrisas, y cada día que hemos pasado juntos."
        },
        {
            type: "text",
            value: "Gracias mi amor y feliz Año Nuevo."
        },
        {
            type: "video",
            value: {
                'title': 'No Te Apartes De Mí',
                'avatarName': 'Vicentico',
                'avatarURL': 'https://i.scdn.co/image/1952b415dc3837711efd4b5213ff6be79c9424ca',
                'videoURL': 'https://www.youtube.com/embed/tJOu5ukveiQ'
            },
        }
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(AnioNuevo);