import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import ImageFetcherService from './../../utils/ImageFetcherService';
import { getRandomColor } from './../../utils/utils';
import GeneralSitePage from '../../components/GeneralSitePage';

class Navidad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2017/navidad/bunnies.jpg']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="navidad-2017">
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.image} />
                </div>
                <p>
                    Hola mi amor.<br/><br/>
                    Antes que nada quiero desearte una feliz navidad. Y mandarte tu abrazo porque no pudimos darnos nuestro abrazo de navidad el viernes.<br /><br />
                    Quiero decirte que eres una niña perfecta, perfecta para un gordito dormilón como yo. Perfecta para que este gordito sea feliz, y perfecta para ser la esposa del niño que escribe esta cartita. Ah, y también, que seas la mamá de sus gorditos.<br /><br />
                    Se que yo no soy el novio perfecto, pero cada día me esfuerzo más y más para que estemos felices toda la vida, porque quiero estar contigo cada día de mi vida. Conocerte no solo ha sido el regalo de navidad anticipado más hermoso del mundo, sino que también conocerte, y hacerte mi novia, y mi futura esposa es lo más bonito de la vida.<br /><br />
                    Y se que aunque hemos tenido ratos malos, siempre buscaré que todos sean ratos bonitos, que cada día sea bonito para los dos y compensar los ratos malos con muchos muchos muchos ratos bonitos como novios.<br /><br />
                    También quiero agradecerte por el dibujito tan bonito que hiciste de nosotros, los conejitos. Y por los chocolates más ricos del mundo que venían con una cartita muy linda que me escribiste. Siempre que veo las cartitas que me has escrito lloro porque me escribes las cosas más bonitas, y cada que pienso en ti, que es cada segundo de mi vida, me enamoro más y más y más de ti. Porque si, eres perfecta, eres el amor de mi vida, y lo se, estoy seguro de que eres aquella persona que fue hecha para mí.<br /><br />
                    Finalmente, quiero que sepas que te amo, te amo mucho, no tienes idea de cuanto te amo mi corazón de melón. Y debes estar segura de que eres mi todo, y que voy a estar contigo siempre, pues al igual que tú, no sabría que hacer si tú no estás a mi lado, acompañandome en cada segundo de mi vida.<br /><br />
                    Espero que cenes muy rico, y que está sea la primera navidad de muchas en las que estemos juntos. Te mando muchos abrazos. Muchos de osito, muchos abrazos para arrullarte y miles de abrazos de Navidad, mi gizmita hermosa. TE AMOOOOO FLAQUITA.<br /><br />
                    Para terminar esta cartita, te dejo una canción, porque en verdad, doy gracias al cielo por haberte conocido, mi corazón de melón.<br /><br />
                </p>
                <Card body outline color={getRandomColor()}>
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe src="https://www.youtube.com/embed/8doSkJdyLNA" allowfullscreen></iframe>
                    </div>
                    <CardBody>
                        <CardTitle>Sabes Una Cosa</CardTitle>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

Navidad.defaultProps = {
    content: [
        {
            type: "image",
            value: '2017/navidad/bunnies.jpg',
            title: 'Conejitos',
            description: 'Gracias por el dibujito mi esposa hermosa'
        },
        {
            type: "text",
            value: "Hola mi amor."
        },
        {
            type: "text",
            value: "Antes que nada quiero desearte una feliz navidad. Y mandarte tu abrazo porque no pudimos darnos nuestro abrazo de navidad el viernes."
        },
        {
            type: "text",
            value: "Quiero decirte que eres una niña perfecta, perfecta para un gordito dormilón como yo. Perfecta para que este gordito sea feliz, y perfecta para ser la esposa del niño que escribe esta cartita. Ah, y también, que seas la mamá de sus gorditos."
        },
        {
            type: "text",
            value: "Se que yo no soy el novio perfecto, pero cada día me esfuerzo más y más para que estemos felices toda la vida, porque quiero estar contigo cada día de mi vida. Conocerte no solo ha sido el regalo de navidad anticipado más hermoso del mundo, sino que también conocerte, y hacerte mi novia, y mi futura esposa es lo más bonito de la vida."
        },
        {
            type: "text",
            value: "Y se que aunque hemos tenido ratos malos, siempre buscaré que todos sean ratos bonitos, que cada día sea bonito para los dos y compensar los ratos malos con muchos muchos muchos ratos bonitos como novios."
        },
        {
            type: "text",
            value: "También quiero agradecerte por el dibujito tan bonito que hiciste de nosotros, los conejitos. Y por los chocolates más ricos del mundo que venían con una cartita muy linda que me escribiste. Siempre que veo las cartitas que me has escrito lloro porque me escribes las cosas más bonitas, y cada que pienso en ti, que es cada segundo de mi vida, me enamoro más y más y más de ti. Porque si, eres perfecta, eres el amor de mi vida, y lo se, estoy seguro de que eres aquella persona que fue hecha para mí."
        },
        {
            type: "text",
            value: "Finalmente, quiero que sepas que te amo, te amo mucho, no tienes idea de cuanto te amo mi corazón de melón. Y debes estar segura de que eres mi todo, y que voy a estar contigo siempre, pues al igual que tú, no sabría que hacer si tú no estás a mi lado, acompañandome en cada segundo de mi vida."
        },
        {
            type: "text",
            value: "Espero que cenes muy rico, y que está sea la primera navidad de muchas en las que estemos juntos. Te mando muchos abrazos. Muchos de osito, muchos abrazos para arrullarte y miles de abrazos de Navidad, mi gizmita hermosa. TE AMOOOOO FLAQUITA."
        },
        {
            type: "text",
            value: "Para terminar esta cartita, te dejo una canción, porque en verdad, doy gracias al cielo por haberte conocido, mi corazón de melón."
        },
        {
            type: "video",
            value: {
                'title': 'Sabes una cosa',
                'avatarName': 'Luis Miguel',
                'avatarURL': 'https://i.scdn.co/image/d9854aae131602d7a5b07cc980a150730e0114a3',
                'videoURL': 'https://www.youtube.com/embed/8doSkJdyLNA'
            },
        }
    ]
}

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Navidad);