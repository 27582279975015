import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageFetcherService from './../../utils/ImageFetcherService';
import GeneralSitePage from '../../components/GeneralSitePage';
import { getRandomColor } from './../../utils/utils';

class Cumpleanios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2019/cumpleanios/cumpleanios.png']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="cumpleanios-2018">
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.image} />
                </div>
                <p>
                    Buenos días flaquita, te amo mucho y quiero desearte un bonito feliz cumpleaños.
                    <br/>
                </p>
                <p>
                    Antes que nada quiero despertarte con ojito de Botón, y darte un beso de bella durmiente.
                    <br /><br />
                    El día de hoy chiquita, quiero desearte un feliz cumpleaños, y estoy muy feliz de que pueda pasar tu cumpleaños a tu lado, mimándote, cuidándote, dándote miles de besitos. También como me dijiste hace ratito, quiero comer a tu lado, quiero compartir cositas contigo y darte muchos besitos.
                    <br /><br />
                    También quiero decirte que eres mi chiquita hermosa, y mi esposa, y quiero que seas la persona más feliz del mundo, porque en verdad amor, estos últimos años, tú has sido el regalo más bonito todos los días. Yo también quiero ser tu regalito siempre, que cada día sepas que tienes a un osito encimoso y amoroso que te va a cuidar, que te va a amar y que te ve a hacer feliz siempre. Quiero que seas feliz a mi lado, hoy y todos los días de nuestra vida, quiero que seas mi esposa. Ya sé que te lo he pedido muchas veces, pero quiero que te cases conmigo, quiero que vivas a mi lado, para así no extrañarnos nunca, y siempre estar juntitos, quiero verte a mi lado al despertar, y abrazarte todas las noches para dormir a tu lado. 
                    <br /><br />
                    En un ratito te voy a ver amor, y te voy a llevar otros dulcesitos para hacerte más feliz el día de tu cumpleaños, y espero que tu regalo de cumpleaños te guste mucho amor, porque te lo doy con mucho cariño, al igual que esta pagina donde puedo escribirte cartitas que podrás ver siempre.
                    <br /><br />
                    Gracias por ser mi esposa estos años, por amarme mucho y sobre todo por apoyarme, mimarme y cuidarme mucho siempre.
                    <br /><br />
                    <h3>¡TE AMO!</h3>
                </p>
            </div>
        );
    }
}

Cumpleanios.defaultProps = {
    content: [
        {
            type: "image",
            value: '2019/cumpleanios/cumpleanios.png',
            title: 'Botoncito',
            description: 'Cuando se dormía con nosotros'
        },
        {
            type: "text",
            value: "Buenos días flaquita, te amo mucho y quiero desearte un bonito feliz cumpleaños.",
        },
        {
            type: "text",
            value: "Antes que nada quiero despertarte con ojito de Botón, y darte un beso de bella durmiente."
        },
        {
            type: "text",
            value: "El día de hoy chiquita, quiero desearte un feliz cumpleaños, y estoy muy feliz de que pueda pasar tu cumpleaños a tu lado, mimándote, cuidándote, dándote miles de besitos. También como me dijiste hace ratito, quiero comer a tu lado, quiero compartir cositas contigo y darte muchos besitos.",
        },
        {
            type: "text",
            value: "También quiero decirte que eres mi chiquita hermosa, y mi esposa, y quiero que seas la persona más feliz del mundo, porque en verdad amor, estos últimos años, tú has sido el regalo más bonito todos los días. Yo también quiero ser tu regalito siempre, que cada día sepas que tienes a un osito encimoso y amoroso que te va a cuidar, que te va a amar y que te ve a hacer feliz siempre. Quiero que seas feliz a mi lado, hoy y todos los días de nuestra vida, quiero que seas mi esposa. Ya sé que te lo he pedido muchas veces, pero quiero que te cases conmigo, quiero que vivas a mi lado, para así no extrañarnos nunca, y siempre estar juntitos, quiero verte a mi lado al despertar, y abrazarte todas las noches para dormir a tu lado."
        },
        {
            type: "text",
            value: "En un ratito te voy a ver amor, y te voy a llevar otros dulcesitos para hacerte más feliz el día de tu cumpleaños, y espero que tu regalo de cumpleaños te guste mucho amor, porque te lo doy con mucho cariño, al igual que esta pagina donde puedo escribirte cartitas que podrás ver siempre.",
        },
        {
            type: "text",
            value: "Gracias por ser mi esposa estos años, por amarme mucho y sobre todo por apoyarme, mimarme y cuidarme mucho siempre."
        },
        {
            type: "header",
            value: "¡TE AMO!",
            size: 3,
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Cumpleanios);