import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { setVersion } from './store/actions/Index';
import  { connect } from 'react-redux';

import Layout from './components/Layout';

class Wrapper extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        let version = parseInt(localStorage.getItem('version'));
        if (isNaN(version))
            version = 2020;

        this.props.setVersion(version);
    }

    render() {
        let version = this.props.version;
        let routes = this.props.routes;
        let availableRoutes = [];

        Object.keys(routes).map((year) => {
            if (version >= year)
                availableRoutes = availableRoutes.concat(routes[year]);
        });

        return (
            <BrowserRouter>
                <Layout>
                    <Routes>
                        {
                            availableRoutes.map((route) => 
                                <Route key={route.path} path={route.path}
                                    element={ <route.component/> }
                                    exact
                                />
                            )
                        }
                    </Routes>
                </Layout>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
        routes: state.routing.routes,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setVersion: (version) => dispatch(setVersion(version))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);