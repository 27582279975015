import React, { Component } from 'react';
import { Site } from 'tabler-react';
import  { connect } from 'react-redux';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return this.render2020Version();
    }

    render2017Version() {
        return (
            <footer className="footer">
                <div className="container">
                    <hr />
                    <p>© Kid A</p>
                </div>
            </footer>
        );
    }

    render2020Version() {
        return <Site.Footer copyright="Kid A ®"/>;
    }
}

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Footer);