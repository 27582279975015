import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class AnioNuevo extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

AnioNuevo.defaultProps = {
    content: [
        {
            type: "image",
            value: '2020/anio-nuevo.jpg',
            title: 'Osita',
            description: 'Me gusta mucho ayudarte siempre mi amor, aunque te canses porque estoy muy pesado.'
        },
        {
            type: "text",
            value: `Hola mi amor, quiero desearte un feliz año nuevo.`
        },
        {
            type: "text",
            value: `También quiero decirte que me siento triste por no comprar tu bolsita a tiempo y que se agotaran, perdóname por eso mi amor.`
        },
        {
            type: "text",
            value: `Ahora puse una foto que tomé de tí, porque quiero que sepas que amo estar contigo, no importa si nos quedamos dormidos, si cenamos juntitos en Navidad, si vemos películas o si estoy a tu lado ayudándote o viendote hacer tu tarea. Me gusta mucho estar a tu lado, y te amo con todo mi corazón de oso amoroso.`
        },
        {
            type: "text",
            value: `Quiero agradecerte mucho por estar otro año más con osito, y sobre todo, por apoyarme mucho este año. Por entenderme, por hablar todos los días conmigo, y por ser siempre la osita más amorosa y hermosa del mundo.`
        },
        {
            type: "text",
            value: `Este año fue bonito porque me pediste un chocolate mientras dormías y fue chistoso, porque siempre me pides cosas bonitas cuando duermes, como tu Goofy, o tu bubulubu blanco y tus taquitos de suadero. Siempre eres muy linda mi amor, y me siento muy afortunado de tenerte en mi vida.`
        },
        {
            type: "text",
            value: `Te amo mucho mi amor, y todos los días eres la esposa más hermosa y amorosa, y quiero estar siempre a tu lado. Quiero que estemos juntos siempre, para estar todo el día en pijama, y dándonos masajito para dormir bonito, como Bustanis.`
        },
        {
            type: "text",
            value: `Te amo con todo mi corazón, y en verdad te agradezco mucho por apoyarme este año, y estar a mi lado. Te amo mi amor, y quiero que sigamos muchos, muchos años más, juntos.`
        },
        {
            type: "text",
            value: `Te amo mi amor.`
        },
        {
            type: "header",
            value: `¡Feliz Año Nuevo!`,
            size: 4,
        },
    ],
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(AnioNuevo);