import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageFetcherService from './../../utils/ImageFetcherService';
import GeneralSitePage from '../../components/GeneralSitePage';

class Febrero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2020/febrero.jpg']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div className="febrero-2018">
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.image} />
                </div>
                {
                    this.props.paragraphs.map((p, i) =>
                        <p key={i}>
                            {p}
                            <br></br>
                        </p>
                    )
                }
            </div>
        );
    }
}

Febrero.defaultProps = {
    paragraphs: [
        `Hola esposa, ¡Feliz día del amor y la amistad! perdón por no poder estar contigo hoy, pero espero que recuerdes que hace un año te regale tu cadenita de Minnie, y que me preparaste sopita y yo te hice un hot dog para comer.`,
        `Tu siempre me haces el esposo más feliz, en todo momento, como cuando me preparas sopita o me compartes de tus escuincles y me llevas a tu camita a darnos abrazos y ver películas. Y también me cuidas siempre, en todo momento, hasta cuando duermo, siempre estás cuidando a tu osito para que no de rodillazos o que no se pueda mover.`,
        `Todo eso siempre me hace amarte más y más, y se que tu eres la esposita con la que quiero estar toda la vida.`,
        `Te amo mucho esposa, y me haces el oso más feliz del mundo, y agradezco lo mucho que me apoyas, lo mucho que me consientes, lo mucho que me mimas y lo mucho que me amas. Te amo con todo mi corazón y me siento muy feliz de haber pasado tres años a tu lado, tres años de los muchos que voy a estar a tu lado. Agradezco que hayas llegado a mi vida, y que sigas a mi lado.`,
        `También quiero decirte que aunque hoy no podamos estar juntos, nosotros vamos a celebrar nuestro día la siguiente semana, con una cena romántica de pozole, y llenándonos de besitos todo el fin de semana. Te amo mucho esposa, ya quiero poder abrazarte y llenarte de miles de besitos.`
    ],
    content: [
        {
            type: "image",
            value: '2020/febrero.jpg',
            title: 'Bebé Bustani',
            description: 'Haciendo caras chistosas'
        },
        {
            type: "text",
            value: `Hola esposa, ¡Feliz día del amor y la amistad! perdón por no poder estar contigo hoy, pero espero que recuerdes que hace un año te regale tu cadenita de Minnie, y que me preparaste sopita y yo te hice un hot dog para comer.`
        },
        {
            type: "text",
            value: `Tu siempre me haces el esposo más feliz, en todo momento, como cuando me preparas sopita o me compartes de tus escuincles y me llevas a tu camita a darnos abrazos y ver películas. Y también me cuidas siempre, en todo momento, hasta cuando duermo, siempre estás cuidando a tu osito para que no de rodillazos o que no se pueda mover.`,
        },
        {
            type: "text",
            value: `Todo eso siempre me hace amarte más y más, y se que tu eres la esposita con la que quiero estar toda la vida.`,
        },
        {
            type: "text",
            value: `Te amo mucho esposa, y me haces el oso más feliz del mundo, y agradezco lo mucho que me apoyas, lo mucho que me consientes, lo mucho que me mimas y lo mucho que me amas. Te amo con todo mi corazón y me siento muy feliz de haber pasado tres años a tu lado, tres años de los muchos que voy a estar a tu lado. Agradezco que hayas llegado a mi vida, y que sigas a mi lado.`,
        },
        {
            type: "text",
            value: `También quiero decirte que aunque hoy no podamos estar juntos, nosotros vamos a celebrar nuestro día la siguiente semana, con una cena romántica de pozole, y llenándonos de besitos todo el fin de semana. Te amo mucho esposa, ya quiero poder abrazarte y llenarte de miles de besitos.`,
        }
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Febrero);