import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageFetcherService from './../../utils/ImageFetcherService';
import GeneralSitePage from '../../components/GeneralSitePage';

class Julio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
        }
    }

    componentWillMount() {
        const service = new ImageFetcherService();
        let images = service.fetchImages(['2018/julio/botoncito.jpg']);
        this.setState({
            image: images[0],
        });
    }

    render() {
        if (this.props.version === 2017)
            return this.render2017Version();
        else
            return <GeneralSitePage content={this.props.content}/>;
    }

    render2017Version() {
        return (
            <div>
                <div className="text-center">
                    <img className="img-fluid banner" src={this.state.image} />
                </div>
                <p>
                    Hola ¡Flaquita!<br />
                    Antes que nada, quiero decirte que te amo mucho. Te amo con todo mi corazón, y esta vez en lugar de hacerte una cartita, quise que fuera un poquito diferente dándote el código para que leyeras lo que te escribí. También quizás veas que la página es un poquito diferente, pues ya la moví a React, sin embargo deje todo el contenido tal cual y como estaba, solo cambia en algunas cositas su diseño.<br />
                    Muchas gracias por apoyarme amor, siempre me apoyas, y se que aunque hemos tenido problemas, seguimos adelante, y quiero que sepas que no importa que, siempre voy a estar contigo, y no te dejaré sola, porque desde que nos comprometimos con un anillito de duende, ya somos esposos y te quiero a mi lado toda la vida.<br />
                    Gracias también por el primer año de novios, soy el más feliz del mundo a tu lado, y esta cartita, además del regalo que venía con el código para entrar a ella, sea un bonito regalo, que te guste y que represente este año y todos los demás años que van a venir siendo novios y esposos.<br />
                    Tú último regalo me encanto, senti muy bonito, y me gusto mucho poder ir comiendo tus dulces mientras iba de viaje.<br />
                    Te amo mucho mi amor, espero te guste mucho tu regalo que te traje.<br />
                    Te amo, te amo, te amo mi chiquita hermosa, mucho!!!.
                </p> 
            </div>
        )
    }
}

Julio.defaultProps = {
    content: [
        {
            type: "image",
            value: '2018/julio/botoncito.jpg',
            title: 'Botoncito',
            description: 'Una foto de bebé Botón'
        },
        {
            type: "text",
            value: "Hola ¡Flaquita!",
        },
        {
            type: "text",
            value: "Antes que nada, quiero decirte que te amo mucho. Te amo con todo mi corazón, y esta vez en lugar de hacerte una cartita, quise que fuera un poquito diferente dándote el código para que leyeras lo que te escribí. También quizás veas que la página es un poquito diferente, pues ya la moví a React, sin embargo deje todo el contenido tal cual y como estaba, solo cambia en algunas cositas su diseño."
        },
        {
            type: "text",
            value: "Muchas gracias por apoyarme amor, siempre me apoyas, y se que aunque hemos tenido problemas, seguimos adelante, y quiero que sepas que no importa que, siempre voy a estar contigo, y no te dejaré sola, porque desde que nos comprometimos con un anillito de duende, ya somos esposos y te quiero a mi lado toda la vida."
        },
        {
            type: "text",
            value: "Gracias también por el primer año de novios, soy el más feliz del mundo a tu lado, y esta cartita, además del regalo que venía con el código para entrar a ella, sea un bonito regalo, que te guste y que represente este año y todos los demás años que van a venir siendo novios y esposos."
        },
        {
            type: "text",
            value: "Tú último regalo me encanto, senti muy bonito, y me gusto mucho poder ir comiendo tus dulces mientras iba de viaje.",
        },
        {
            type: "text",
            value: "Te amo mucho mi amor, espero te guste mucho tu regalo que te traje.",
        },
        {
            type: "text",
            value: "Te amo, te amo, te amo mi chiquita hermosa, mucho!!!.",
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Julio);