import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import  { connect } from 'react-redux';

class Layout extends Component {
    constructor(props) {
        super(props);
    }
    
    render() 
    {
        let styleVersion = '';
        let siteVersion = this.props.version;
        switch(siteVersion) {
            case 2017:
                styleVersion = 'v2017';
                break;
            case 2020:
                styleVersion = 'v2020';
                break;
            default:
                styleVersion = 'v2020';
        }

        return (
            <div className={styleVersion}>
                <Header/>
                    <div className="container content">{this.props.children}</div>
                <Footer/>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Layout);