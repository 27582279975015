import { SET_VERSION } from './../actions/ActionTypes';

const initialState = {
    version: 2020,
    availableVersions: [
        2017,
        2020,
    ]
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_VERSION:
            localStorage.setItem('version', `${action.version}`);
            return {
                ...state,
                version: action.version,
            }
        default:
            return state;
    }
}

export default reducer;