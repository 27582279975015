import { createStore, combineReducers } from 'redux';
import VersioningReducer from './reducers/Versioning';
import RoutingReducer from './reducers/Routing';

const rootReducer = combineReducers({
    versioning: VersioningReducer,
    routing: RoutingReducer,
});

const configureStore = () => {
    return createStore(rootReducer); 
};

export default configureStore;