export default class NavLink {

    constructor(name, route, childs = [], isHome = false) {
        this.name = name;
        this.route = route;
        this.isHome = isHome;
        this.childs = childs;
    }

    isActive() {
        if (this.isHome) {
            const aux = `${window.baseUrl}/`;
            return window.location.href == aux;
        }
        
        const route = window.location.href;
        return route.indexOf(this.route) !== -1;
    }

}