import React, { Component } from 'react';
import { connect } from 'react-redux';
import GeneralSitePage from '../../components/GeneralSitePage';

class Cumpleanios extends Component {
    render() {
        return <GeneralSitePage content={this.props.content} />;
    }
}

Cumpleanios.defaultProps = {
    content: [
        {
            type: "image",
            value: '2024/cumpleanios/hello_mono.jpg',
            title: 'El Kitty Mono',
            description: 'Feliz cumpleaños esposa hermosa.'
        },
        {
            type: "header",
            value: `¡Feliz cumpleaños monito!`
        },
        {
            type: "text",
            value: `Te amo mucho, y quiero que esposa tenga un feliz cumpleaños. Hoy es un día para cuidar y hacer muy feliz a mi monito loco.`
        },
        {
            type: "text",
            value: `Perdon si no había puesto cosas aquí para esposa en Navidad y Año Nuevo. Osito te ama mucho, y eres mi vida y mi todo.`
        },
        {
            type: "text",
            value: `Gracias por estar con osito estos años y dejarme compartir 7 cumpleaños a tu lado. Yo quiero mimar a esposa y comprarle un pastel diferente todos los cumpleaños de su vida... Y también consentirte con postre.`
        },
        {
            type: "text",
            value: `Porque monito siempre quiere postre.`
        },
        {
            type: "text",
            value: `Te amo, gracias por compartirme de tus 300 gramos de chocolates y de tu bolsita de gomitas locas de Sanborns, aunque no durarán ni un día.`
        },
        {
            type: "text",
            value: `Espero que mañana puedas disfrutar tu bolsita de doctorcito y ser feliz usando la locura que llevabas tanto tiempo pidiendo.`
        },
        {
            type: "text",
            value: `Me haces muy feliz, y me hace feliz ver a mi monito siendo feliz y muy loquito. Es bonito ver como te emocionas con tus locuras o como hueles tus productos favoritos.`
        },
        {
            type: "text",
            value: `Ya quiero verte, para poder darte tu abrazo, llenarte de besitos, ir a nadar y después comer un pastelito loco junto a mono.`
        },
        {
            type: "header",
            value: `¡Te amo mucho esposa hermosa! !Feliz Cumpleaños¡`,
            size: 4,
        },
    ]
};

const mapStateToProps = state => {
    return {
        version: state.versioning.version,
    }
}

export default connect(mapStateToProps, null)(Cumpleanios);
